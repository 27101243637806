.cls1 { /*!*/ }
.cls2 {
composes: cls1;

opacity: 0;
transition: 0.5s;
flex-shrink: 0;
display: block;
height: 13px;
width: 13px;
&.visible {
opacity: 1;
}
}