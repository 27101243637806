.cls1 { /*!*/ }
.cls2 {
composes: cls1;

min-height: 100%;
min-width: 100%;
height: 100%;
&.disabled {
opacity: 0.33;
}
}