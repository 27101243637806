.cls1 { /*!*/ }
.cls2 {
composes: cls1;

height: 16px;
width: 16px;
position: absolute;
left: 50%;
top: 50%;
transform: translate(-50%, -50%);
}