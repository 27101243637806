@import './constants.scss';

@mixin transition() {
  transition: 0.5s;
}

@mixin lato-font() {
  font-family: lato, -apple-system, 'Roboto', BlinkMacSystemFont, 'Segoe UI', serif;
  letter-spacing: normal;
}

@mixin thin-scrollbar($color: $color-dark-gray, $background: $color-white, $width: 5px) {
  // TODO: Временно закоментировано до выхода 85-й версии Firefox
  //scrollbar-color: $color $background;
  scrollbar-width: thin;
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    width: $width;
    height: $width;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: $background;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: $color;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: $color;
  }
}

@mixin long-content {
  text-overflow: ellipsis;
  max-width: 100%;
  overflow: hidden;
}

@mixin entity-card($border-color: #f5a623) {
  border-left: solid 10px $border-color;
  background-color: $color-gray;
}


@mixin no-spin-buttons {
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type=number] {
    -moz-appearance:textfield;
  }
}
