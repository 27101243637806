.cls1 { /*!*/ }
.cls2 {
composes: cls1;

cursor: pointer;
display: flex;
width: 100%;
height: 100%;
padding: 8px 12px;
&.disabled {
cursor: auto;
}
}