.cls1 { /*!*/ }
.cls2 {
composes: cls1;

display: block;
width: 24px;
height: 24px;
flex-shrink: 0;
position: relative;
cursor: pointer;
&:before {
content: '';
position: absolute;
transform: translate(-50%, -50%);
left: 50%;
top: 50%;
width: 0;
height: 0;
border-style: solid;
border-width: 5px 5px 0 5px;
border-color: #008bc6 transparent transparent transparent;
transition: 0.1s;
}
&.open {
&:before {
transform: translate(-50%, -50%) rotate(180deg);
}
}
}