.cls1 { /*!*/ }
.cls2 {
composes: cls1;

position: absolute;
left: 0;
top: 0;
width: 100%;
height: 100%;
overflow: auto;
display: flex;
padding: 48px 8px 126px;
box-sizing: border-box;
}