.cls1 { /*!*/ }
.cls2 {
composes: cls1;

width: 100%;
height: 100%;
margin-right: 12px;
line-height: 14px;
text-overflow: ellipsis;
overflow: hidden;
}