@import './constants.scss';

.toaster {
  z-index: $z-index-toastify;
  font-size: 12px;
}

.preview-margin {
  margin-left: 22px;
}

.force-body-scroll {
	overflow-y: scroll;
}

body {
  margin: 0;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  &.block {
    overflow-y: scroll;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

body, button, a, input {
  font-family: lato, -apple-system, 'Roboto', BlinkMacSystemFont, 'Segoe UI', serif;
}

.MuiFormGroup-root {
  display: flex;
  flex-flow: row;
}

.MuiIconButton-label {
  svg {
    width: 16px;
    height: 16px;
  }
}

.MuiRadio-root {
  color: #008ac6;
}

.MuiRadio-colorPrimary.Mui-checked {
  color: #008ac6;
}
