@import '../../../../styles/constants.scss';
.cls1 { /*!*/ }
.cls2 {
composes: cls1;

text-align: left;
font-size: 12px;
display: flex;
align-items: center;
width: 100%;
padding: 10px 11px 10px;
cursor: pointer;
&:hover {
background-color: rgba($color-gray, 0.5);
}
&.withCheckbox {
padding: 2px 6px 2px;
}
}