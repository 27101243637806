.cls1 { /*!*/ }
.cls2 {
composes: cls1;

display: flex;
width: 100%;
height: 100%;
align-items: center;
cursor: auto;
padding-left: 12px;
padding-right: 12px;
&.alterable {
padding-right: 6px;
}
&.first {
padding-left: 18px;
}
}