@value cls1 as a0 from "ClassifierValueCell-RemoveButton.module.scss";


.cls1 { /*!*/ }
.cls2 {
composes: cls1;

display: flex;
min-width: 100%;
min-height: 100%;
padding: 8px 12px;
box-sizing: border-box;
&:hover {
.a0 {
opacity: 1;
}
}
}