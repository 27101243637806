.cls1 { /*!*/ }
.cls2 {
composes: cls1;

margin-top: 120px;
font-size: 16px;
line-height: 20px;
width: 100%;
padding: 11px;
text-transform: none;
font-weight: 600;
}