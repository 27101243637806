@import '../../../../styles/mixins.scss';
.cls1 { /*!*/ }
.cls2 {
composes: cls1;

@include long-content();
display: flex;
letter-spacing: normal;
width: 100%;
font-size: 12px;
font-weight: bold;
white-space: nowrap;
text-align: left;
}