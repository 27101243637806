.cls1 { /*!*/ }
.cls2 {
composes: cls1;

padding-top: 6px;
padding-bottom: 6px;
padding-left: 20px;
padding-right: 8px;
white-space: nowrap;
font-weight: bold;
}