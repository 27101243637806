.cls1 { /*!*/ }
.cls2 {
composes: cls1;

pointer-events: none;
position: absolute;
width: 59px;
height: 27px;
background-image: linear-gradient(90deg, rgba(#f5f5f5, 0) 6%, rgba(#f5f5f5, 1) 67%);
left: -59px;
transition: opacity 0.25s;
&.hidden {
opacity: 0;
}
}