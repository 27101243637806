.cls1 { /*!*/ }
.cls2 {
composes: cls1;

margin-right: 11px;
margin-left: auto;
font-size: 12px;
color: rgba(0, 0, 0, 0.87);
opacity: 0.33;
flex-shrink: 0;
}