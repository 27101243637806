.cls1 { /*!*/ }
.cls2 {
composes: cls1;

margin-right: 9px;
width: 100%;
font-size: 12px;
line-height: 19px;
border-collapse: collapse;
border-bottom: solid 1px #d9d9d9;
overflow: hidden;
}