.cls1 { /*!*/ }
.cls2 {
composes: cls1;

text-overflow: ellipsis;
font-size: 12px;
font-weight: bold;
display: inline-block;
width: auto;
flex-grow: 1;
}