.cls1 { /*!*/ }
.cls2 {
composes: cls1;

position: absolute;
right: 25px;
height: 16px;
width: 16px;
display: flex;
justify-content: center;
align-items: center;
opacity: 0;
visibility: hidden;
transition: visibility 0.25s, opacity 0.25s;
}