.cls1 { /*!*/ }
.cls2 {
composes: cls1;

height: 100%;
display: flex;
align-items: center;
font-weight: bold;
&.header {
background: rgb(255, 165, 0);
font-size: 14px;
color: white;
}
&.section {
background: rgb(252, 226, 183);
}
&.notSelectable {
padding-left: 18px;
}
}