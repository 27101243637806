.cls1 { /*!*/ }
.cls2 {
composes: cls1;

width: 32px;
height: 32px;
border-radius: 2px;
border: solid 1px #cacaca;
margin-right: 5px;
flex-shrink: 0;
cursor: pointer;
display: flex;
align-items: center;
justify-content: center;
}