@import "~ag-grid-community/src/styles/ag-theme-base/sass/ag-theme-base";
@import "~ag-grid-community/src/styles/ag-theme-alpine/sass/ag-theme-alpine-default-params";
@import './mixins.scss';

$font-size: 12px;

$z-index-ghost: 12;
$z-index-columns: 11;
$z-index-pinned-rows: 9;
$z-index-header: 8;
$z-index-section:  7;
$z-index-overlay: 10;

$default-horizontal-padding: 12px;
$first-horizontal-padding: 17px;

@mixin default-cell-padding() {
  padding: 6px $default-horizontal-padding;
}

.ag-theme-admin {
  @include lato-font();
  @include ag-allow-color-param-access-with-ag-param(false);

  $params: $ag-theme-alpine-default-params;
  @include ag-theme-base($params);

	&.ag-dnd-ghost {
		z-index: $z-index-ghost;
	}

  .ag-cell {
    line-height: 18px;
    white-space: normal !important;
    padding: 5px $default-horizontal-padding;
  }

  .ag-ltr {
    .ag-cell {
      border-right-color: #d9d9d9 !important;
      &:last-of-type {
        border-right: none;
      }
    }
  }

  .ag-cell-value {
    width: 100%;
    height: 100%;
  }

  .ag-filter-toolpanel-header,
  .ag-filter-toolpanel-search,
  .ag-status-bar,
  .ag-header-row,
  .ag-panel-title-bar-title {
    font-weight: 700;
    @include ag-color-property(color, header-foreground-color);
  }

  %ag-pinned-row-end {
    content: '';
    position: absolute;
    height: calc(100% - 20px);
    top: 10px;
    width: 1px;
    @include ag-color-property(background-color, border-color);
  }

  .ag-ltr {
    .ag-pinned-right-header .ag-header-row:after {
      @extend %ag-pinned-row-end;
      right: 0;
    }
  }

  .ag-rtl {
    .ag-pinned-left-header .ag-header-row:before {
      @extend %ag-pinned-row-end;
      left: 0;
    }
  }

  .ag-row {
    font-size: $font-size;
    border-color: #d9d9d9;
    padding: 0;
    height: 20px;
  }


	.ag-row-invalid {
		background: rgba(255, 0, 0, 0.1);
		&.ag-row-hover {
			background: #EADBE4;
		}
	}

	.ag-row-blocked {
		.ag-cell {
			opacity: 0.33;
			pointer-events: none;
		}
	}

	.ag-grid-blocked-cell {
		opacity: 0.33;
		pointer-events: none;
	}

  %ag-text-input {
    min-height: ag-param(grid-size) * 4;
    border-radius: ag-param(border-radius);

    @include ag-theme-rtl((
        padding-left: ag-param(grid-size),
    ));
  }

  .ag-tab {
    padding: ag-param(grid-size) * 1.5;
    transition: color 0.4s;
  }

  .ag-tab-selected {
    @include ag-color-property(color, alpine-active-color);
  }

  .ag-menu {
    @include ag-color-property(background-color, control-panel-background-color);
  }

  .ag-menu-header {
    @include ag-color-property(background-color, control-panel-background-color);
    padding-top: 1px; // hack to align column menu tab header border with header row border
  }

  .ag-tabs-header {
    @include ag-border(primary, bottom);
  }

  .ag-charts-settings-group-title-bar,
  .ag-charts-data-group-title-bar,
  .ag-charts-format-top-level-group-title-bar {
    padding: ag-param(grid-size) ag-param(grid-size) * 2;
    line-height: ag-param(icon-size) + ag-param(grid-size) - 2;
  }

  .ag-chart-mini-thumbnail {
    @include ag-color-property(background-color, background-color);
  }

  .ag-chart-settings-nav-bar {
    @include ag-border(secondary, top);
  }

  .ag-group-title-bar-icon {
    @include ag-theme-rtl((margin-right: ag-param(grid-size)))
  }

  .ag-charts-format-top-level-group-toolbar {
    margin-top: ag-param(grid-size);
    @include ag-theme-rtl((padding-left: ag-param(icon-size) / 2 + ag-param(grid-size) * 2));
  }

  .ag-charts-format-sub-level-group {
    border-left: dashed 1px;
    @include ag-color-property(border-left-color, border-color);
    padding-left: ag-param(grid-size);
    margin-bottom: ag-param(grid-size) * 2;
  }

  .ag-charts-format-sub-level-group-title-bar {
    padding-top: 0;
    padding-bottom: 0;
    background: none;
    font-weight: 700;
  }

  .ag-charts-format-sub-level-group-container {
    padding-bottom: 0;
  }

  .ag-charts-format-sub-level-group-item:last-child {
    margin-bottom: 0;
  }

  .ag-dnd-ghost {
    font-size: ag-param(font-size) - 1;
    font-weight: 700;
  }

  .ag-side-buttons {
    width: ag-param(grid-size) * 5;
  }

  .ag-standard-button {
    appearance: none;
    -webkit-appearance: none;
    border-radius: ag-param(border-radius);
    border: 1px solid;
    @include ag-color-property(border-color, alpine-active-color);
    @include ag-color-property(color, alpine-active-color);
    @include ag-color-property(background-color, background-color);
    font-weight: 600;
    padding: ag-param(grid-size) ag-param(grid-size) * 2;

    &:hover {
      @include ag-color-property(border-color, alpine-active-color);
      @include ag-color-property(background-color, row-hover-color);
    }

    &:active {
      @include ag-color-property(border-color, alpine-active-color);
      @include ag-color-property(background-color, alpine-active-color);
      @include ag-color-property(color, background-color);
    }
  }

  .ag-menu-header {
    min-width: 240px;
  }

  .ag-column-drop-vertical {
    min-height: 75px;
    max-height: 175px;
  }

  .ag-tool-panel-wrapper {
    width: 250px;
  }

  .ag-column-drop-vertical-title-bar {
    padding: ag-param(grid-size) * 2;
    padding-bottom: 0px;
  }

  .ag-column-drop-vertical-empty-message {
    display: flex;
    align-items: center;
    border: dashed 1px;
    @include ag-color-property(border-color, border-color);
    margin: ag-param(grid-size) * 2;
    padding: ag-param(grid-size) * 2;
  }

  .ag-column-drop-empty-message {
    @include ag-color-property(color, foreground-color);
    opacity: 0.75;
  }

  .ag-status-bar {
    font-weight: normal;
  }

  .ag-status-name-value-value {
    font-weight: 700;
  }

  .ag-paging-number, .ag-paging-row-summary-panel-number {
    font-weight: 700;
  }

  .ag-column-drop-cell-button {
    opacity: 0.5;

    &:hover {
      opacity: 0.75;
    }
  }

  .ag-header-cell-menu-button,
  .ag-side-button-button,
  .ag-tab,
  .ag-panel-title-bar-button,
  .ag-header-expand-icon,
  .ag-column-group-icons,
  .ag-group-expanded .ag-icon,
  .ag-group-contracted .ag-icon,
  .ag-chart-settings-prev,
  .ag-chart-settings-next,
  .ag-group-title-bar-icon,
  .ag-column-select-header-icon,
  .ag-floating-filter-button-button,
  .ag-filter-toolpanel-expand,
  .ag-chart-menu-icon {
    &:hover {
      @include ag-color-property(color, alpine-active-color);
    }
  }


  .ag-chart-settings-card-item.ag-not-selected:hover {
    opacity: 0.35;
  }

  .ag-panel-title-bar-button {
    @include ag-theme-rtl((
        margin-left: ag-param(grid-size) * 2,
        margin-right: ag-param(grid-size)
    ));
  }

  .ag-filter-toolpanel-group-container {
    padding-left: ag-param(grid-size);
  }

  .ag-filter-toolpanel-instance-filter {
    border: none;
    @include ag-color-property(background-color, control-panel-background-color);
    border-left: dashed 1px;
    @include ag-color-property(border-left-color, border-color);
    margin-left: ag-param(grid-size) + ag-param(icon-size) / 2 - 1px;
    padding-left: ag-param(icon-size) / 2;
    margin-right: ag-param(grid-size) * 2;
  }

  .ag-set-filter-list {
    padding-top: ag-param(grid-size) / 2;
    padding-bottom: ag-param(grid-size) / 2;
  }

  .ag-overlay-no-rows-wrapper.ag-layout-auto-height {
    padding-top: 60px;
  }

  .ag-date-time-list-page-entry-is-current {
    @include ag-color-property(background-color, alpine-active-color);
  }

  @include ag-allow-color-param-access-with-ag-param(true);


  .ag-pinned-left-cols-container, .ag-pinned-left-floating-top {
    z-index: 1;
    overflow: visible;
  }

  .ag-root-wrapper {
    border-radius: 0;
    border: none;
  }

  .ag-overlay {
    z-index: $z-index-overlay;
    background: rgba(255, 255, 255, 0.25);
  }

  .ag-grid-custom-overlay-indicator {
    height: 40px;
    animation: appear 1s;
    @keyframes appear {
      0% {
        opacity: 0
      }
      25% {
        opacity: 0
      }
      100% {
        opacity: 1;
      }
    }
  }


  .ag-header {
    z-index: $z-index-columns;
    background: #f5f5f5;
    border: none;
  }

  .ag-header-cell-resize {
    &:after {
      top: 0;
      height: 100%;
      background: white;
      width: 1px;
    }
  }

  .ag-pinned-left-header {
    border-right: solid 1px white;
  }

  .ag-cell-wrapper {
    align-items: flex-start;
  }

  // Убирает границу у строки сверху, если она соприкасается с функцией
  .ag-first-row {
    border-top: solid 0px transparent;
  }

  // Изменения стилей в случае, если header скрыт:
  &--hide-header {
    .ag-header {
      display: none;
    }
  }
}

// Костыли
.ag-theme-admin {
  // Костыль №1:
  // Для того, чтобы скролл был на всю длину таблицу мы скрываем скролл у закрепленной части таблицы
  // и добавляем искуственный padding у скролла основной её части, для этого и необходим content-box,
  // чтобы последний влиял на размер
  .ag-horizontal-left-spacer {
    display: none;
  }

  .ag-body-horizontal-scroll-container {
    box-sizing: content-box;
  }

  // Костыль №3:
  .ag-floating-top {
    position: absolute;
    top: 43px;
    overflow: visible !important;
    z-index: $z-index-pinned-rows;
    border-color: #dde2eb !important;
    border-top: solid 1px #dde2eb;
    border-bottom: none !important;
  }

  // Если header скрыт, костыль немного меняется:
  &--hide-header {
    .ag-floating-top {
      top: 0;
    }
  }

  // Костыль №4: чтобы скролл справа всегда отображался
  .ag-body-viewport {
    overflow-y: scroll !important;
  }

  // Костыль №5
  // Предотвращает моргание иконки
  .ag-header-add-button-cell {
    padding: 0;
  }

  // Костыль №6:
  .ag-react-container {
    width: 100%;
    height: 100%;
  }
}

// Костыль №8:
// Для исправления скролла на Mac'е (вынесен из темы специально сюда), поскольку размер
// скролла проверяется на элементе, создаваемом вне таблицы
.mac-browser {
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 8px;
    height: 8px;
    background: $color-gray;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #CBCBCB;
    box-shadow: 0 0 1px rgba(255, 255, 255, .5);
  }
  .prevent-selection {
    * {
      user-select: none !important;
    }
  }
}

// Ячейки:
.ag-theme-admin {

  .ag-grid-custom-header-cell {
    @include default-cell-padding();
    height: 100%;
    overflow: hidden;
    transition: 0.1s;
    &__textarea {
      @include thin-scrollbar();
      padding: 0 !important;
      overflow: hidden;
      width: 100%;
      height: 100%;
      resize: none;
      outline: none;
      border: none !important;
      border-radius: 0 !important;
      background: none !important;
      &:focus {
        box-shadow: none !important;
      }
      &--hidden {
        display: none;
      }
    }
    &__content {
      height: 100%;
      width: 100%;
      word-break: break-word;
      &--hidden {
        display: none;
      }
    }
    &--editing {
      box-shadow: inset 0px 0px 2px $color-blue;
    }

  }

  .ag-header-cell {
    padding: 0;
  }

  .ag-header-cell-text {
    @include lato-font();
    color: rgba(0, 0, 0, 0.87);
    font-weight: bold;
  }

  .ag-grid-header-rendered {
    width: 100%;
    height: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .ag-grid-text-cell {
    @include lato-font();
    white-space: normal !important;
  }

  .ag-grid-checkbox-cell {
    padding: 1px 0 0;
    background-color: #c8eeff;
    border: none !important;
  }

  .ag-grid-level-cell {
    text-align: center;

    &-3 {
      background: #C1D5F4;
    }

    &-2 {
      background: #E1EAFA;
    }
  }

  .ag-grid-header-cell {
    @include lato-font();
    font-size: 14px;
    height: 28px;
    padding: 0;
    line-height: 28px !important;
    font-weight: bold;
    background: rgb(255, 165, 0);
    color: white;
    border: none !important;
  }

  .ag-grid-section-cell {
    @include lato-font();
    height: 28px;
    padding: 0;
    line-height: 28px !important;
    font-weight: bold;
    background: rgb(252, 226, 183);
    white-space: nowrap !important;
    overflow: visible !important;
    border: none !important;
  }


  .ag-grid-first-cell {
    padding-left: 17px;
  }

  .ag-grid-centered-cell {
    @extend .ag-grid-text-cell;
    text-align: center;
    display: block;
  }

	.ag-grid-bold-cell {
		font-weight: bold;
	}

  .ag-grid-all-centered-cell {
    @extend .ag-grid-text-cell;
    text-align: center;
    justify-content: center;
    align-items: center;
    display: flex;
    height: 100%;
  }

  .ag-header-custom-cell {
    padding: 0;
  }

	.ag-header-first {
		padding-left: 8px;
	}

  .ag-grid-custom-cell {
    padding: 0;
    border: none;
    box-sizing: border-box;
  }

  .ag-grid-reference-cell {
    padding: 0;
    border: none;
    box-sizing: border-box;
  }

  .ag-grid-invalid-cell {
    background: rgba(255, 0, 0, 0.19);
  }


  .ag-grid-header-row {
    border: none !important;
    z-index: $z-index-header;
  }

  .ag-grid-section-row {
    border: none !important;
    z-index: $z-index-section;
  }

  .ag-grid-with-counter-cell {
    @include default-cell-padding();
    display: flex;
    align-items: center;
    &__content {
      margin-right: 16px;
    }
    &__number {
      background: #d8d8d8;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 18px;
      height: 18px;
      padding-right: 3px;
      letter-spacing: -0.01em;
      border-radius: 50%;
      font-size: 12px;
      margin-left: auto;
      margin-top: 1px;
      margin-bottom: 1px;
      flex-shrink: 0;
      transition: opacity 0.5s;
      opacity: 1;
      &--hidden {
        opacity: 0;
      }
    }
  }

  .ag-grid-progress-cell {
    @include default-cell-padding();
    display: flex;
    align-items: center;
    &__bar {
      background: #d8d8d8;
      position: relative;
      overflow: hidden;
      border-radius: 5px;
      height: 8px;
      width: 100%;
    }
    &__fill {
      background: $color-blue;
      height: 100%;
      border-radius: 5px;
    }
    &__number {
      margin-left: 10px;
    }
  }
  .ag-grid-score-cell {
    @extend .ag-grid-centered-cell;
    @include default-cell-padding();
    height: 100%;
  }

  .ag-grid-with-padding-color-cell {
    padding: 5px 17px;
    height: 100%;
    position: relative;
  }

  .ag-grid-color-line-cell {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 6px;
  }

  .ag-grid-icon-link-cell {
    padding: 10px;
  }

  .ag-grid-with-padding-flex-cell {
    @include default-cell-padding();
    height: 100%;
    display: flex;
    align-items: center;
  }
  .ag-grid-status-cell {
    @include default-cell-padding();
    display: flex;
    align-items: center;
    &__icon {
      width: 12px;
      height: 12px;
      margin-right: 10px;
      border-radius: 50%;
    }
    &__status {
      margin-left: 8px;
      &:first-letter {
        text-transform: uppercase;
      }
      &--active {
        color: #81c784;
      }
      &--deleted {
        color: #e57373;
      }
      &--blocked {
        color: #757575;
      }
    }
  }

  .ag-grid-with-padding-cell {
    @include default-cell-padding();
    text-overflow: ellipsis;
    height: 100%;
  }

  .ag-grid-password-cell {
    padding: 5px 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    &__content {
      display: flex;
      letter-spacing: 0.025em;
      width: 48px;
      justify-content: center;
      align-content: center;
      margin-right: 6px;
    }
    &__circle {
      height: 8px;
      width: 8px;
      border-radius: 50%;
      background-color: #d8d8d8;
      &:not(:last-child) {
        margin-right: 4px;
      }
    }
    &__icon-wrapper {
      height: 19px;
      margin-left: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      position: relative;
    }

    &__pulse {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 16px;
      width: 16px;
    }
    &__icon, &__pulse {
      transition: 0.5s;
      &--hidden {
        opacity: 0;
      }
    }
  }
  .ag-grid-link-cell {
    @include default-cell-padding;
    padding-left: $default-horizontal-padding;
    display: block;

    &__link {
      display: inline;
      cursor: pointer;
      color: $color-black;
      transition: 0.5s;

      &:hover {
        color: $color-blue;
      }

      &--disabled {
        cursor: auto;
        color: $color-black;
        &:hover {
          color: $color-black;
        }
      }
    }
  }

	.ag-grid-first-cell {
		@include default-cell-padding();
		padding-left: $first-horizontal-padding;
	}

  .ag-grid-link {
    @include default-cell-padding;
    display: block;
    color: $color-black;
    transition: 0.5s;
    padding-left: 17px;

    &:hover {
      color: $color-blue;
    }
  }
  .ag-grid-action-cell {
    @include default-cell-padding;
    display: block;
    color: $color-blue;
    transition: 0.5s;
    padding-left: 17px;
    cursor: pointer;
    &.disabled {
      color: #757575;
      cursor: unset;
    }
  }
}




