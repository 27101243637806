.cls1 { /*!*/ }
.cls2 {
composes: cls1;

display: flex;
align-items: flex-start;
width: 100%;
font-weight: bold;
cursor: pointer;
&.disabled {
cursor: auto;
}
}