.cls1 { /*!*/ }
.cls2 {
composes: cls1;

position: absolute;
top: 11px;
right: 11px;
width: 24px;
height: 24px;
display: flex;
align-items: center;
justify-content: center;
cursor: pointer;
}