.cls1 { /*!*/ }
.cls2 {
composes: cls1;

position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
transition: 0.5s;
opacity: 0;
height: 40px;
z-index: -1;
&.visible {
opacity: 1;
visibility: visible;
z-index: 1;
}
&.delay {
transition-delay: 0s;
}
&.delay.visible {
transition-delay: 1s;
}
}