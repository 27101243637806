.cls1 { /*!*/ }
.cls2 {
composes: cls1;

display: flex;
align-items: center;
min-width: 0;
width: 100%;
&.sortable {
cursor: pointer;
}
}