.cls1 { /*!*/ }
.cls2 {
composes: cls1;

border-radius: 5px;
border: solid 1px #dcdcdc;
color: #212121;
font-size: 16px;
font-family: lato, -apple-system, Roboto, BlinkMacSystemFont, serif;
min-width: 10px;
width: 100%;
max-width: 100%;
box-sizing: border-box;
line-height: 1.4;
font-weight: 700;
-webkit-appearance: none;
height: 44px;
background-color: transparent;
animation: onAutoFillCancel 5000s linear;
position: relative;
padding: 10px 9px 10px 24px;

&.lighten {
color: #4d4d4d;
}

&.withIcon {
padding: 10px 28px 10px 24px;
}

&[type='number'] {
-moz-appearance: textfield;
}

&:-webkit-autofill {
color: #212121;
font-size: 16px;
background-color: #f4f;
animation: onAutoFillStart 5000s linear;
}

&:-webkit-autofill,
&:-webkit-autofill:hover,
&:-webkit-autofill:focus {
background-color: initial;
-webkit-text-size-adjust: initial;
-webkit-text-fill-color: initial;
-webkit-box-shadow: 0 0 0 1000px #fff inset;
}

&::-webkit-credentials-auto-fill-button,
&::-webkit-contacts-auto-fill-button {
position: relative;
top: 2px;
}

&:focus {
outline: none;
}

&:disabled {
color: rgba(#212121, 0.6);
}

@keyframes onAutoFillStart {
// Workaround to force nesting this keyframe
0% {
position: relative;
}
100% {
position: relative;
}
}

@keyframes onAutoFillCancel {
// Workaround to force nesting this keyframe
0% {
position: relative;
}
100% {
position: relative;
}
}
}