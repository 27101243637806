.cls1 { /*!*/ }
.cls2 {
composes: cls1;

position: absolute;
pointer-events: none;
width: 59px;
height: 50px;
background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 6%, rgba(255, 255, 255, 1) 67%);
left: -59px;
transition: opacity 0.25s;
&.hidden {
opacity: 0;
}
&.gray {
background-image: linear-gradient(90deg, rgba(245, 245, 245, 0) 6%, rgba(245, 245, 245, 1) 67%);
}
}