@font-face {
  font-family: 'Lato';
  src: url('../fonts/Lato-Semibold.woff2') format('woff2'),
  url('../fonts/Lato-Semibold.woff') format('woff'),
  url('../fonts/Lato-Semibold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'Lato';
  src: url('../fonts/Lato-Bold.woff2') format('woff2'),
  url('../fonts/Lato-Bold.woff') format('woff'),
  url('../fonts/Lato-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: block;
}

@font-face {
	font-family: 'Lato';
	src: url('../fonts/Lato-Black.woff2') format('woff2'),
	url('../fonts/Lato-Black.woff') format('woff'),
	url('../fonts/Lato-Black.ttf') format('truetype');
	font-weight: 900;
	font-style: normal;
	font-display: block;
}

@font-face {
  font-family: 'Lato';
  src: url('../fonts/Lato-Light.woff2') format('woff2'),
  url('../fonts/Lato-Light.woff') format('woff'),
  url('../fonts/Lato-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'Lato';
  src: url('../fonts/Lato-Regular.woff2') format('woff2'),
  url('../fonts/Lato-Regular.woff') format('woff'),
  url('../fonts/Lato-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
