.cls1 { /*!*/ }
.cls2 {
composes: cls1;

border: solid 1px #d9d9d9;
transition: background 0.5s;
&.first {
border-top: none;
}
&.last {
border-bottom: none;
}
&.red {
background: #ffe6e6;
}
}