@value cls1 as a0 from "SimpleDropdown-ClearWrap.module.scss";


@import '../../../styles/constants.scss';
.cls1 { /*!*/ }
.cls2 {
composes: cls1;

height: 32px;
background: white;
font-size: 12px;
padding: 0px 16px;
padding-right: 32px;
display: flex;
align-items: center;
position: relative;
cursor: pointer;
user-select: none;
color: rgba(0, 0, 0, 0.87);
&.border {
border: solid 1px #ededed;
}

&.showOnClear {
padding-right: 42px;
&:hover {
.a0 {
visibility: visible;
opacity: 1;
}
}
}
&.disabled {
cursor: not-allowed;
user-select: auto;
}
&.invalid {
border: solid 1px $color-red;
}

&.multiline {
height: auto;
padding-top: 6px;
padding-bottom: 6px;
align-items: flex-start;
}

&.active {
border: solid 1px #008ac6;
}
}