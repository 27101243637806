@import '../../../styles/constants.scss';
.cls1 { /*!*/ }
.cls2 {
composes: cls1;

height: 32px;
line-height: 32px;
padding: 0 11px;
font-size: 12px;
background: white;
color: $color-black;
border: solid 1px #ededed;
outline: 0;
cursor: pointer;
position: relative;
}