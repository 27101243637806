.cls1 { /*!*/ }
.cls2 {
composes: cls1;

letter-spacing: normal;
width: 100%;
padding-left: 22px;
padding-right: 22px;
font-size: 12px;
font-weight: bold;
white-space: nowrap;
line-height: 32px;
text-align: left;
&.withIcon {
display: flex;
}
}