@import '../../../../styles/constants.scss';
.cls1 { /*!*/ }
.cls2 {
composes: cls1;

height: 24px;
min-height: 24px;
border: solid 1px #f5f5f5;
display: flex;
align-items: center;
margin: 4px 9px 6px 12px;
padding-left: 2px;
}