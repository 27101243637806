.cls1 { /*!*/ }
.cls2 {
composes: cls1;

display: flex;
align-items: center;
justify-content: center;
padding-top: 2px;

width: 72px;
height: 30px;
border-radius: 2px;

font-size: 24px;
font-weight: 300;
}