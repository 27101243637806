.cls1 { /*!*/ }
.cls2 {
composes: cls1;

height: 100%;
position: relative;
opacity: 0;
transition: 0.5s;

&.visible {
opacity: 1;
}
}