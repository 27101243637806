.cls1 { /*!*/ }
.cls2 {
composes: cls1;

width: 16px;
height: 16px;
border: solid 1px #757575;
background-color: white;
display: flex;
align-items: center;
justify-content: center;
transition: background 0.5s;
&.disabled {
background-color: #dedede;
}
}