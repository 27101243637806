.cls1 { /*!*/ }
.cls2 {
composes: cls1;

display: flex;
align-items: center;
justify-content: center;
cursor: pointer;
height: 100%;
width: 100%;
position: relative;
}