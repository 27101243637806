@import '../../../../styles/constants.scss';
.cls1 { /*!*/ }
.cls2 {
composes: cls1;

padding: 7px 12px;
font-size: 12px;
&:hover {
background-color: rgba($color-gray, 0.5);
}
}