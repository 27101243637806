.cls1 { /*!*/ }
.cls2 {
composes: cls1;

position: relative;
padding: 1px 18px 0px 18px;
height: 32px;
white-space: nowrap;
&.full {
width: 100%;
white-space: normal;
}
&.green {
background: #cfe9cf;
}
&.blocked {
opacity: 0.33;
pointer-events: none;
}
}