@import '../../../../common/src/styles/constants.scss';
.cls1 { /*!*/ }
.cls2 {
composes: cls1;

padding-top: 8px;
margin-top: 11px;
&:before {
top: -8px;
position: relative;
content: '';
margin-left: 24px;
margin-right: 24px;
height: 1px;
background: $color-warm-gray;
display: block;
width: calc(100% - 48px);
}
}