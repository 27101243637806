.cls1 { /*!*/ }
.cls2 {
composes: cls1;

position: relative;
display: flex;
justify-content: space-between;
width: 1129px;
height: 480px;
margin: 40px auto 0;
}