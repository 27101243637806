.cls1 { /*!*/ }
.cls2 {
composes: cls1;

width: 100%;
border-bottom: solid 1px #e0e0e0;
min-width: 0;
position: relative;
&.beforeButtons {
margin-right: 15px;
}
}