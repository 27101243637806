@import '../../../styles/constants.scss';
.cls1 { /*!*/ }
.cls2 {
composes: cls1;

width: 38px;
height: 32px;
display: flex;
align-items: center;
justify-content: center;
fill: $color-blue;
transition: 0.5s;
border: solid 1px #d9d9d9;
flex-shrink: 0;
&.black {
fill: $color-black;
}
&:hover {
background: $color-blue;
border-color: $color-blue;
color: white;
fill: white;
}
&.disabled {
fill: $color-dark-gray;
pointer-events: none;
}
&.left {
border-top-left-radius: 6px;
border-bottom-left-radius: 6px;
}
&.right {
border-top-right-radius: 6px;
border-bottom-right-radius: 6px;
}
}