.cls1 { /*!*/ }
.cls2 {
composes: cls1;

display: inline-block;
&.disabled {
svg {
fill: #aaa;
}
}
&.needMargin {
margin-right: 8px;
}
}