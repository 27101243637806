.cls1 { /*!*/ }
.cls2 {
composes: cls1;

color: #757575;
text-align: center;
font-size: 12px;
line-height: 14px;
height: 30px;
width: 30px;
display: inline-block;
&:first-letter {
text-transform: uppercase;
}
&:not(:last-of-type) {
margin-right: 10px;
}
}