.cls1 { /*!*/ }
.cls2 {
composes: cls1;

height: calc(100% - 60px);
position: relative;

&.hasInput {
height: calc(100% - 95px);
}
}