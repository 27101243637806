.cls1 { /*!*/ }
.cls2 {
composes: cls1;

position: absolute;
display: block;
height: 24px;
width: 24px;
left: 50%;
top: 50%;
transform: translate(-50%, -50%);
}