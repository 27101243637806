.cls1 { /*!*/ }
.cls2 {
composes: cls1;

border: solid 1px #cacaca;
background: none;
font-size: 12px;
line-height: 32px;
height: 32px;
padding: 0 7px;
outline: none;
display: block;
width: 100%;
box-sizing: border-box;
&::placeholder {
opacity: 0.4;
color: #212121;
}
}