.cls1 { /*!*/ }
.cls2 {
composes: cls1;

width: 100%;
padding-right: 0;
height: 30px;
display: flex;
align-items: center;
&:not(:last-child) {
padding-right: 2px;
}
&.editing {
cursor: pointer;
}
}