@import '../../../../styles/constants.scss';
.cls1 { /*!*/ }
.cls2 {
composes: cls1;

position: relative;
&.grow {
min-width: 0;
width: 100%;
}
}