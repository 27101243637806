.cls1 { /*!*/ }
.cls2 {
composes: cls1;

min-height: 100%;
min-width: 100%;
height: 100%;
display: flex;
align-items: center;
justify-content: center;
&.disabled {
opacity: 0;
}
}