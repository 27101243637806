.cls1 { /*!*/ }
.cls2 {
composes: cls1;

background: white;
box-sizing: border-box;
border: solid 1px #ededed;
padding-top: 7px;
margin-top: -1px;
width: 100%;
}