.cls1 { /*!*/ }
.cls2 {
composes: cls1;

font-size: 12px;
line-height: 32px;
color: #484848;
margin-bottom: 31px;
font-weight: bold;
&:first-letter {
text-transform: uppercase;
}
}