.cls1 { /*!*/ }
.cls2 {
composes: cls1;

width: 30px;
height: 30px;
font-size: 12px;
font-weight: bold;
line-height: 14px;
display: flex;
align-items: center;
justify-content: center;
cursor: pointer;
border-radius: 50%;

&:not(:last-child) {
margin-right: 10px;

&:after {
content: '';
background: #008ac6;
height: 30px;
width: 10px;
position: absolute;
right: -10px;
top: 0;
opacity: 0;
}
}

&.today {
border-radius: 50%;
}

&.today,
&:hover {
background: #f5f5f5;
}

&.end,
&.start,
&.intermediate {
background: #008ac6;
color: white;
}

&.intermediate,
&.start {
position: relative;
border-radius: 0;

&:after {
opacity: 1;
}
}

&.start {
border-radius: 50% 0 0 50%;
}

&.end {
border-radius: 0 50% 50% 0;
}

&.single {
border-radius: 50%;

&:after {
opacity: 0;
}
}
}