@import './mixins.scss';

a {
  text-decoration: none;
}

body {
  box-sizing: border-box;
  text-size-adjust: none;
}


* {
  box-sizing: border-box;
  @include lato-font();
}

input[type=number] {
  -moz-appearance:textfield;
}

