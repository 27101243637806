.cls1 { /*!*/ }
.cls2 {
composes: cls1;

overflow: hidden;
text-overflow: ellipsis;
white-space: nowrap;
width: 100%;

&:before {
content: attr(data-name);
}
}