@import '../../styles/constants.scss';
.cls1 { /*!*/ }
.cls2 {
composes: cls1;

display: flex;
align-items: center;
font-size: 12px;
line-height: 24px;
color: $color-black;
border-bottom: solid 1px #e6e8f1;
height: 48px;
padding-left: 22px;
padding-right: 22px;
}