.cls1 { /*!*/ }
.cls2 {
composes: cls1;

user-select: none;
background: white;
border: solid 1px #ededed;
padding: 23px 18px 29px 18px;
&.double {
padding: 23px 39px 29px 18px;
}
}