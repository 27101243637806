@import '../../styles/constants.scss';
@import '../../styles/mixins.scss';
.cls1 { /*!*/ }
.cls2 {
composes: cls1;

@include transition();
color: #757575;
height: 50px;
font-size: 12px;
font-weight: bold;
padding: 7px 16px;
margin-right: 16px;
line-height: 19px;
display: flex;
align-items: center;
justify-content: center;
position: relative;
cursor: pointer;
white-space: nowrap;
&:first-child {
padding-left: 24px;
}
&.active {
border-radius: 4px 4px 0 0;
background: #ffffff;
color: $color-black;
}
&.dark.active {
background: #f5f5f5;
}
}