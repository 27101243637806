.cls1 { /*!*/ }
.cls2 {
composes: cls1;

position: absolute;
right: 8px;
height: 16px;
width: 16px;
display: flex;
justify-content: center;
align-items: center;
}