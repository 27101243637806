@import '../../../styles/constants.scss';
.cls1 { /*!*/ }
.cls2 {
composes: cls1;

padding: 6px 22px;
fill: $color-blue;
color: $color-blue;
font-size: 12px;
line-height: 9px;
display: flex;
align-items: center;
cursor: pointer;
text-align: center;
}