.cls1 { /*!*/ }
.cls2 {
composes: cls1;

font-size: 10px;
font-weight: bold;
top: -7px;
position: relative;
border-radius: 8px;
padding: 2px 4px 0px;
height: 14px;
min-width: 14px;
margin-left: 2px;
line-height: 10px;
display: flex;
align-items: center;
justify-content: center;
color: white;
&.color-red {
background-color: #e57373;
}
&.color-orange {
background-color: #ef6c02;
}
&.type-warning {
margin-left: -2px;
}
}