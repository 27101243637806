.cls1 { /*!*/ }
.cls2 {
composes: cls1;

margin-top: auto;
border-top: solid 1px #e8e8e8;
padding-top: 32px;
font-size: 12px;
line-height: 16px;
padding-bottom: 32px;
}