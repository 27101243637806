.appear-enter {
  opacity: 0;
}

.appear-enter-active {
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
}

.appear-exit {
  opacity: 1;
}

.appear-exit-active {
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.slow-appear-enter {
  opacity: 0;
}

.slow-appear-enter-active {
  opacity: 1;
  transition: opacity 0.5s;
}

.slow-appear-exit {
  opacity: 1;
}

.slow-appear-exit-active {
  opacity: 0;
  transition: opacity 0.5s;
}
