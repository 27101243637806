.cls1 { /*!*/ }
.cls2 {
composes: cls1;

font-size: 24px;
line-height: 36px;
margin-top: 12px;
font-weight: 600;
text-align: center;
padding-left: 12px;
padding-right: 12px;
}