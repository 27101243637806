.cls1 { /*!*/ }
.cls2 {
composes: cls1;

border: none;
outline: 0;
min-width: 0;
text-overflow: ellipsis;
padding-left: 8px;
font-size: 12px;
width: 100%;
}