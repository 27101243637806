.cls1 { /*!*/ }
.cls2 {
composes: cls1;

position: absolute;
font-size: 16px;
font-weight: 600;
transition: all 0.1s ease-in-out;
left: 24px;
white-space: nowrap;
max-width: calc(100% - 48px) !important;
text-overflow: ellipsis;
overflow: hidden;
border-bottom: 1px solid transparent;
z-index: 2;
color: rgba(33, 33, 33, 0.5);
user-select: none;
cursor: text;
top: 12px;
display: flex;

&:first-letter {
text-transform: capitalize !important;
}
&:before {
display: block;
left: -2px;
right: -2px;
position: absolute;
top: 2px;
height: 12px;
background-color: #fff;
z-index: -1;
}

&.focus {
font-size: 12px;
line-height: 1.2;
color: rgba(33, 33, 33, 0.5);
padding: 0 4px;
top: -10px;
left: 22px;

&:before {
content: '';
}
}
&.disabled {
color: #dcdcdc;
cursor: default;
}
}