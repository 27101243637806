.cls1 { /*!*/ }
.cls2 {
composes: cls1;

padding-right: 24px;
vertical-align: baseline;
text-align: right;
width: 135px;
flex-shrink: 0;
white-space: nowrap;
&.bold {
font-weight: bold;
}
}