.cls1 { /*!*/ }
.cls2 {
composes: cls1;

padding: 0px;
display: inline-flex;
align-items: center;
&.disabled {
opacity: 0.5;
cursor: not-allowed;
}
}