.cls1 { /*!*/ }
.cls2 {
composes: cls1;

display: flex;
align-items: center;
justify-content: center;
&.disabled {
cursor: not-allowed;
}
}