@import '../../styles/constants.scss';
@import '../../styles/mixins.scss';
.cls1 { /*!*/ }
.cls2 {
composes: cls1;

color: #757575;
font-size: 12px;
font-weight: bold;
line-height: 19px;
padding-bottom: 4px;
padding-top: 4px;
position: relative;
white-space: nowrap;
display: inline-block;
&.pointer {
cursor: pointer;
}
&.uppercase {
text-transform: uppercase;
}
&:after {
transition: 0.5s;
content: '';
background: transparent;
width: 100%;
bottom: 0;
height: 1px;
left: 0;
display: block;
position: absolute;
}
&:global(.active) {
&.hideBackground {
background: none;
}
background: $color-gray;
color: $color-black;
&:after {
background: $color-black;
}
}
}