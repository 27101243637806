.cls1 { /*!*/ }
.cls2 {
composes: cls1;

width: 100%;
height: 100%;
font-size: 12px;
border: solid 1px #ededed;
padding-top: 1px;
padding-left: 10px;
padding-right: 6px;
transition: border 0.5s;
background: white;
&.invalid {
border-color: red;
}
&:focus {
outline: none;
}
&::placeholder {
color: rgba(0, 0, 0, 0.2);
}
}