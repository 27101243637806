.cls1 { /*!*/ }
.cls2 {
composes: cls1;

background: #d8d8d8;
height: 8px;
width: 100%;
outline: none;
border: solid 1px transparent;
cursor: inherit;
transition: 0.5s;
&.value-bad {
background: #f7a9a8;
border-color: #f7a9a8;
}
&.value-medium {
background: #fff59c;
border-color: #fff59c;
}
&.value-good {
background: #c5e1a5;
border-color: #c5e1a5;
}
&.value-perfect {
background: #80c684;
border-color: #80c684;
}
&.editing.value-empty {
background: white;
border: solid 1px #008ac6;
}
&.editing.invalid {
border: solid 1px #ff4256;
}
}