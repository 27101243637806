.cls1 { /*!*/ }
.cls2 {
composes: cls1;

display: flex;
height: 34px;
border-bottom: solid 1px #cacaca;
align-items: center;
width: 100%;

&.last {
border-bottom: none;
}
}