.cls1 { /*!*/ }
.cls2 {
composes: cls1;

font-size: 12px;
margin: 15px auto 15px 12px;
color: #008ac6;
cursor: pointer;
position: sticky;
bottom: 0;
background: #ffffff;
padding: 3px 0;
}