.cls1 { /*!*/ }
.cls2 {
composes: cls1;

display: block;
width: 100%;
text-overflow: ellipsis;
outline: none;
height: 25px;
border: solid 1px #d9d9d9;
background-color: #ffffff;
}