.cls1 { /*!*/ }
.cls2 {
composes: cls1;

border: none;
outline: none;
min-width: 0;
text-overflow: ellipsis;
width: 100%;
font-size: 12px;
line-height: 16px;
padding-top: 1px;
}