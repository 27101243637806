.cls1 { /*!*/ }
.cls2 {
composes: cls1;

opacity: 0;
width: 13px;
height: 13px;
position: absolute;
left: 12px;
top: 9px;
pointer-events: none;
transition: 0.5s;
&.visible {
opacity: 1;
}
}