.cls1 { /*!*/ }
.cls2 {
composes: cls1;

position: absolute;
width: 22px;
height: 22px;
right: 10px;
top: 10px;
cursor: pointer;
fill: rgba(117, 117, 117, 0.54);
}