.cls1 { /*!*/ }
.cls2 {
composes: cls1;

width: 218px;
background: white;
border-radius: 6px;
box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
padding-top: 16px;
padding-bottom: 14px;
max-height: 250px;
position: relative;
}