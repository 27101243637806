.cls1 { /*!*/ }
.cls2 {
composes: cls1;

display: flex;
height: 20px;
width: 20px;
align-items: center;
justify-content: center;
cursor: pointer;
}