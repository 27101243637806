.cls1 { /*!*/ }
.cls2 {
composes: cls1;

width: 4px;
height: 4px;
box-shadow: 0 0 0 1.5px #fff, inset 0 0 1px 1px rgba(0, 0, 0, 0.3), 0 0 1px 2px rgba(0, 0, 0, 0.4);
border-radius: 50%;
cursor: hand;
transform: translate(-2px, -2px);
}